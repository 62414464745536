<template>
  <form :data-vv-scope="id" :key="id">
    <div class="confirm-form row">
        <!-- Title-->
      <div class="col-12 mb-6 pr-0 pl-1" v-if="hasSlot('title')">
        <slot name="title"></slot>
      </div>
      <div class="col-md-12 mt-4 mb-3 marked ">
        <h4>Número de pré-reserva: {{ value.referencia }}</h4>
      </div>
      <!-- Geral -->
      <div class="col-md-12 mt-4 mb-3 ">
        <div class="row">
          <div class="col-12">
            <span class="label">Reservado por:</span>
            <span> {{ value.cliente.nome }} {{(value.cliente.cnpjVatnumber) ? ( '(' + value.cliente.cnpjVatnumber  + ')' ) : '' }}</span>
          </div>
        </div>
        <div class="row">
<!--          <div class="col-12 col-md-6">-->
<!--            <span class="label">Modalidade Pagamento:</span>-->
<!--            <span> {{ $util.formatEmpty(value.modalidade_pagamento) }}</span>-->
<!--          </div>-->
          <div class="col-12 col-md-6">
            <span class="label">Operação:</span>
            <span>{{ $util.formatEmpty(value.operacao) }}</span>
          </div>
          <div class="col-12 col-md-6">
            <span class="label">Contato:</span>
            <span> {{(value.idUser) ? value.idUser.fullName : ''}} {{ value.idUser ? '(' + value.idUser.email + ')' : '-' }}</span>
          </div>
          <div class="col-12 col-md-6">
            <span class="label">Número da Referência:</span>
            <span> {{ $util.formatEmpty(value.referencia_cliente) }}</span>
          </div>
        </div>
      </div>

      <!-- Itinerário -->
      <div class="col-md-12 mt-4 mb-3 ">
        <h4 class="subtitle">Itinerário</h4>
        <div class="row row-content">
          <div class="col-12 col-md-12">
            <span class="label">{{ $t('form.proposal.modality')}}:</span>
            <span> {{ modalidade }}</span>
          </div>
          <div class="col-12 col-md-6">
            <span class="label">Origem:</span>
            <span> {{(value.origem) ? value.origem.nome : null }}{{(value.origem && value.origem.pais) ? ', ' + value.origem.pais : ''}}</span>
          </div>
          <div class="col-12 col-md-6">
            <span class="label">Destino:</span>
            <span> {{(value.destino) ? value.destino.nome : null }}{{(value.destino && value.destino.pais) ? ', ' + value.destino.pais : ''}}</span>
          </div>
<!--          <div class="col-12 col-md-6">-->
<!--            <span class="label">Rota:</span>-->
<!--            <span> {{ $util.formatEmpty(value.rota) }}</span>-->
<!--          </div>-->
          <div class="col-12 col-md-6">
            <span class="label">Previsão embarque:</span>
            <span> {{ $util.formatDate(value.previsao_embarque) }}</span>
          </div>
          <div class="col-12 col-md-6">
            <span class="label">Previsão desembarque:</span>
            <span> {{ $util.formatDate(value.previsao_desembarque, 'pt-BR', '-') }}</span>
          </div>
          <div class="col-12 col-md-12">
            <span class="label">Tempo de Viagem Estimado:</span>
            <span> {{ $util.formatEmpty(value.transit_time) }}</span>
          </div>
          <div v-if="value.servico_com_transbordo" class="col-12 col-md-12">
            <span> * Serviço com transbordo </span>
          </div>
        </div>
      </div>

      <!-- Comercial Invoice -->
      <div class="col-md-12 mt-4 mb-3" v-if="value.comercial_invoice">
        <h4 class="subtitle">
          Comercial Invoice
        </h4>

        <div class="col-12 col-md-12 mt-4 mb-3">
          <show-files v-model="value"></show-files>
        </div>
      </div>

      <!-- Equipamentos -->
      <div class="col-md-12 mt-4 mb-3 " v-if="!isLCL">
        <h4 class="subtitle">Equipamento</h4>
        <div class="row row-content" v-for="(equipamento, index) in value.equipamentos" v-bind:key="index">
          <!-- Descrição -->
          <div class="col-12 col-md-12">
            <span class="label">Container:</span>
            <span> {{ equipamento.quantidade }} x {{ equipamento.descricao }}</span>
          </div>

          <!-- Free Time Origem -->
          <div class="col-12 col-md-12">
            <span class="label">Free Time:</span>
            <span> {{(equipamento.free_time_origem) ? $util.formatEmpty(equipamento.free_time_origem) : $util.formatEmpty(equipamento.free_time_destino)  }}</span>
          </div>
        </div>
      </div>

      <!-- Carga -->
      <div class="col-md-12 mt-4 mb-3 ">
        <h4 class="subtitle">Carga</h4>
        <div class="row row-content">
          <div class="col-12 col-md-12">
            <span class="label large">Minha carga não tem temperatura controlada e não inclui qualquer mercadoria pessoal:</span>
            <span> {{ $util.formatBoolean(value.carga.segura_naopessoal)  }}</span>
          </div>
          <div class="col-12 col-md-12" v-if="!value.comercial_invoice">
            <span class="label">NCM:</span>
            <span> {{( value.carga.ncm) ? value.carga.ncm.codigo + ' - ' + value.carga.ncm.descricao : null }}</span>
          </div>
          <div class="col-12 col-md-12" v-if="!value.comercial_invoice">
            <span class="label">Descrição da Mercadoria:</span>
            <span> {{ $util.formatEmpty(value.carga.descricao) }}</span>
          </div>
          <div class="col-12 col-md-6" v-if="!value.comercial_invoice">
            <span class="label">Tipo de Volumes:</span>
            <span> {{ $util.formatEmpty((value.carga.tipo_volume) ? value.carga.tipo_volume.nome : null) }}</span>
          </div>
          <div class="col-12 col-md-6" v-if="!value.comercial_invoice">
            <span class="label">Quantidade de Volumes:</span>
            <span> {{ $util.formatEmpty(value.carga.quantidade) }}</span>
          </div>
          <div class="col-12 col-md-6" v-if="!value.comercial_invoice">
            <span class="label">Peso Bruto:</span>
            <span>{{(value.carga.peso_bruto) ? $util.formatNumber($util.getNumberFromFormated(value.carga.peso_bruto)) : '-' }}</span>
          </div>
          <div class="col-12 col-md-6" v-if="isLCL && !value.comercial_invoice">
            <span class="label">CBM:</span>
            <span>{{(value.carga.cbm) ? $util.formatNumber($util.getNumberFromFormated(value.carga.cbm)) : '-' }}</span>
          </div>
        </div>
      </div>

      <!-- Exportador -->
      <form-confirm-person v-if="!value.comercial_invoice && value.exportador.nome" v-model="value.exportador">
        <template slot="subtitle">Exportador</template>
      </form-confirm-person>

      <!-- Importador -->
      <form-confirm-person v-if="!value.comercial_invoice && value.importador.nome" v-model="value.importador">
        <template slot="subtitle">
          Importador
        </template>
      </form-confirm-person>

      <!-- Notify -->
      <form-confirm-person v-if="!value.comercial_invoice && value.notify.nome" v-model="value.notify">
        <template slot="subtitle">
          Notify
        </template>
      </form-confirm-person>

      <!-- Adicionais -->
      <div class="col-md-12 mt-4 mb-3 ">
        <h4 class="subtitle">
          Adicionais
        </h4>
        <!-- Seguro -->
        <div class="row row-content">
          <div class="col-12 ">
            <span class="label">Seguro:</span>
            <span>&nbsp;
              {{ value.adicionais.seguro.freteMercadoria ? 'Frete + Valor da Mercadoria' : 'Não' }}
              {{(value.adicionais.seguro.freteMercadoria && value.adicionais.seguro.impostos) ? ' / Impostos' : '' }}
              {{(value.adicionais.seguro.freteMercadoria && value.adicionais.seguro.outras_despesas) ? ' / Outras Despesas' : '' }}
            </span>
          </div>
          <div class="col-12 col-md-12" v-show="value.adicionais.seguro.freteMercadoria">
            <span class="label">{{ $t('form.proposal.adicionais.seguro.valor_seguro', { moeda: moedaFrete }) }}</span>
            <span>{{ $util.formatNumber($util.getNumberFromFormated(value.adicionais.seguro.valor_seguro)) }}</span>
          </div>
          <div class="col-12 col-md-6" v-show="value.adicionais.seguro.freteMercadoria">
            <span class="label">{{ $t('form.proposal.adicionais.seguro.valor_frete', { moeda: moedaFrete }) }}</span>
            <span> {{ $util.formatNumber(value.adicionais.seguro.valor_frete) }}</span>
          </div>
          <div class="col-12 col-md-6" v-show="value.adicionais.seguro.freteMercadoria">
            <span class="label">{{ $t('form.proposal.adicionais.seguro.valor_mercadoria', { moeda: moedaFrete }) }}</span>
            <span> {{ $util.formatNumber($util.getNumberFromFormated(value.adicionais.seguro.valor_mercadoria)) }}</span>
          </div>
        </div>
        <!-- Complementar -->
        <div class="row row-content">
          <div class="col-12 ">
            <span class="label" >Informações adicionais:</span>
            <span v-html="value.adicionais.complementar"></span>
          </div>
        </div>
      </div>

      <!-- Taxas -->
      <!-- Taxas de Frete - large devices -->
      <div class="col-md-12 mt-4 mb-3">
        <h4 class="subtitle">
          Taxas de Frete
        </h4>
        <!-- Cabeçalho-->
        <div v-if="screenWidth" class="row row-content table-content">
          <div class="col-md-3">
            <span class="label">Taxas de Frete</span>
          </div>
          <div class="col-md-1">
            <span class="label"></span>
          </div>
          <div class="col-md-3">
            <span class="label">Tarifa / Unidade</span>
          </div>
          <div class="col-md-2">
            <span class="label">Qtde</span>
          </div>
          <div class="col-md-3">
            <span class="label">Total</span>
          </div>
          <!-- Conteúdo -->
          <div class="col-12">
            <div class="row" v-for="(taxa, index ) in taxasFrete" v-bind:key="index">
              <div class="col-12 col-md-3">
                <span >{{ taxa.descricao }}</span>
              </div>
              <div class="col-12 col-md-1">
                <span>{{ taxa.moeda }}</span>
              </div>
              <div class="col-12 col-md-3">
                <span>{{ $util.formatNumber(taxa.valor_unitario) }} / {{ taxa.unidade }}</span>
              </div>
              <div class="col-12 col-md-2">
                <span>{{ $util.formatNumber(taxa.quantidade) }}</span>
              </div>
              <div class="col-12 col-md-3">
                <span>{{ $util.formatNumber(taxa.valor_unitario * taxa.quantidade) }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Taxas de Frete - medium/small devices -->
        <div v-else class="col-12 mb-5">
        <!-- Conteúdo -->
          <div class="row" v-for="(taxa, index ) in taxasFrete" v-bind:key="index">
            <div class="col-12 pb-3">

              <!-- Descrição -->
              <div class="row row-content">
                <div class="col-12">
                  <span class="w-100">{{ taxa.descricao }}</span>
                </div>
              </div>

              <!-- Valor Unitário -->
              <div class="row row-content">
                <div class="col-12">
                  <span class="label">Tarifa / Unidade</span>
                  <span class="pull-right">{{ $util.formatNumber(taxa.valor_unitario) }} / {{ taxa.unidade }}</span>
                </div>
              </div>

              <!-- Quantidade -->
              <div class="row row-content">
                <div class="col-12">
                  <span class="label">Quantidade</span>
                  <span class="pull-right">{{ $util.formatNumber(taxa.quantidade) }}</span>
                </div>
              </div>

              <!-- Moeda -->
              <div class="row row-content">
                <div class="col-12">
                  <span class="label">Moeda</span>
                  <span class="pull-right">{{ taxa.moeda }}</span>
                </div>
              </div>

              <!-- Total -->
              <div class="row">
                <div class="col-12">
                  <span class="label">Total</span>
                  <span class="pull-right">{{ $util.formatNumber(taxa.valor_unitario * taxa.quantidade) }}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- Taxas - Origem -->
      <div class="col-md-12 mt-4 mb-3" v-if="taxasOrigem && taxasOrigem.length > 0">
        <h4 class="subtitle">
          Taxas de Origem
        </h4>
        <!-- Taxas - Origem - large devices -->
        <div v-if="screenWidth" class="col-md-12 mt-4 mb-3">
          <!-- Cabeçalho-->
          <div class="row row-content table-content">
            <div class="col-12 col-md-3">
              <span class="label">Taxas de Origem</span>
            </div>
            <div class="col-12 col-md-1">
              <span class="label"></span>
            </div>
            <div class="col-12 col-md-3">
              <span class="label">Tarifa / Unidade</span>
            </div>
            <div class="col-12 col-md-2">
              <span class="label">Qtde</span>
            </div>
            <div class="col-12 col-md-3">
              <span class="label">Total</span>
            </div>
            <!-- Conteúdo -->
            <div class="col-12">
              <div class="row" v-for="(taxa, index ) in taxasOrigem" v-bind:key="index">
                <div class="col-12 col-md-3">
                  <span >{{ taxa.descricao }}</span>
                </div>
                <div class="col-12 col-md-1">
                  <span>{{ taxa.moeda }}</span>
                </div>
                <div class="col-12 col-md-3">
                  <span>{{ $util.formatNumber(taxa.valor_unitario) }} / {{ taxa.unidade }}</span>
                </div>
                <div class="col-12 col-md-2">
                  <span>{{ $util.formatNumber(taxa.quantidade) }}</span>
                </div>
                <div class="col-12 col-md-3">
                  <span>{{ $util.formatNumber(taxa.valor_unitario * taxa.quantidade) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Taxas - Origem  - medium/small devices -->
        <div v-else class="col-12 mb-5">
        <!-- Conteúdo -->
        <!-- <div class="col-12"> -->
          <div class="row" v-for="(taxa, index ) in taxasOrigem" v-bind:key="index">
            <div class="col-12 pb-3">

              <!-- Descrição -->
              <div class="row row-content">
                <div class="col-12">
                  <span class="w-100">{{ taxa.descricao }}</span>
                </div>
              </div>

              <!-- Valor Unitário -->
              <div class="row row-content">
                <div class="col-12">
                  <span class="label">Tarifa / Unidade</span>
                  <span class="pull-right">{{ $util.formatNumber(taxa.valor_unitario) }} / {{ taxa.unidade }}</span>
                </div>
              </div>

              <!-- Quantidade -->
              <div class="row row-content">
                <div class="col-12">
                  <span class="label">Quantidade</span>
                  <span class="pull-right">{{ $util.formatNumber(taxa.quantidade) }}</span>
                </div>
              </div>

              <!-- Moeda -->
              <div class="row row-content">
                <div class="col-12">
                  <span class="label">Moeda</span>
                  <span class="pull-right">{{ taxa.moeda }}</span>
                </div>
              </div>

              <!-- Total -->
              <div class="row">
                <div class="col-12">
                  <span class="label">Total</span>
                  <span class="pull-right">{{ $util.formatNumber(taxa.valor_unitario * taxa.quantidade) }}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- Taxas - Destino -->
      <div class="col-md-12 mt-4 mb-3" v-if="taxasDestino && taxasDestino.length > 0">
        <h4 class="subtitle">
          Taxas de Destino
        </h4>
        <!-- Taxas - Destino - large devices -->
        <div v-if="screenWidth" class="col-md-12 mt-4 mb-3">
          <!-- Cabeçalho-->
          <div class="row row-content table-content">
            <div class="col-12 col-md-3">
              <span class="label">Taxas de Destino</span>
            </div>
            <div class="col-12 col-md-1">
              <span class="label"></span>
            </div>
            <div class="col-12 col-md-3">
              <span class="label">Tarifa / Unidade</span>
            </div>
            <div class="col-12 col-md-2">
              <span class="label">Qtde</span>
            </div>
            <div class="col-12 col-md-3">
              <span class="label">Total</span>
            </div>
            <!-- Conteúdo -->
            <div class="col-12">
              <div class="row" v-for="(taxa, index ) in taxasDestino" v-bind:key="index">
                <div class="col-12 col-md-3">
                  <span >{{ taxa.descricao }}</span>
                </div>
                <div class="col-12 col-md-1">
                  <span>{{ taxa.moeda }}</span>
                </div>
                <div class="col-12 col-md-3">
                  <span>{{ $util.formatNumber(taxa.valor_unitario) }} / {{ taxa.unidade }}</span>
                </div>
                <div class="col-12 col-md-2">
                  <span>{{ $util.formatNumber(taxa.quantidade) }}</span>
                </div>
                <div class="col-12 col-md-3">
                  <span>{{ $util.formatNumber(taxa.valor_unitario * taxa.quantidade) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Taxas - Destino  - medium/small devices -->
        <div v-else class="col-12 mb-5">
          <!-- Conteúdo -->
          <!-- <div class="col-12"> -->
          <div class="row" v-for="(taxa, index ) in taxasDestino" v-bind:key="index">
            <div class="col-12 pb-3">

              <!-- Descrição -->
              <div class="row row-content">
                <div class="col-12">
                  <span class="w-100">{{ taxa.descricao }}</span>
                </div>
              </div>

              <!-- Valor Unitário -->
              <div class="row row-content">
                <div class="col-12">
                  <span class="label">Tarifa / Unidade</span>
                  <span class="pull-right">{{ $util.formatNumber(taxa.valor_unitario) }} / {{ taxa.unidade }}</span>
                </div>
              </div>

              <!-- Quantidade -->
              <div class="row row-content">
                <div class="col-12">
                  <span class="label">Quantidade</span>
                  <span class="pull-right">{{ $util.formatNumber(taxa.quantidade) }}</span>
                </div>
              </div>

              <!-- Moeda -->
              <div class="row row-content">
                <div class="col-12">
                  <span class="label">Moeda</span>
                  <span class="pull-right">{{ taxa.moeda }}</span>
                </div>
              </div>

              <!-- Total -->
              <div class="row">
                <div class="col-12">
                  <span class="label">Total</span>
                  <span class="pull-right">{{ $util.formatNumber(taxa.valor_unitario * taxa.quantidade) }}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- Total -->
      <div class="col-md-12 mt-4 mb-3 summary confirm-summary">
        <div class="total">
          <div class="row">
            <!-- Desconto -->
            <div class="col-md-6 pb-3" v-if="isCupomAdded">
              <span class="label w-100">Desconto</span>
            </div>
            <div class="col-md-6" v-if="isCupomAdded">
              <div class="row pull-right text-right">
                <div class="col-12" >
                  <div class="currency">
                    <span>{{ value.cupom.moeda }}</span> {{ $util.numberFormat(value.cupom.valor, 2, ',', '.') }}
                  </div>
                </div>
              </div>
            </div>
            <!-- Valor Frete -->
            <div class="col-md-6 pb-3">
              <span class="label w-100">Valor Total do Frete</span>
            </div>
            <div class="col-md-6">
              <div class="row pull-right text-right">
                <div class="col-12" v-for="(moeda, index) in totalMoeda" v-bind:key="index" v-bind:class="{ 'total-negative': moeda.total < 0 }">
                  <div class="currency">
                    <span v-if="index != 0">{{(moeda.total >=0) ? '+' : '-' }} </span>
                    <span>{{ moeda.moeda }}</span> {{ moeda.total >= 0 ? $util.numberFormat(moeda.total, 2, ',', '.') : $util.numberFormat((moeda.total * -1.0), 2, ',', '.') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
</template>

<script>
// @ is an alias to /src

import FormConfirmPerson from './FormConfirmPerson'
import ShowFiles from './ShowFiles'

export default {
  name: 'FormConfirm',
  data () {
    return {
      id: null,
      screen: {
        width: 0,
        height: 0
      }
    }
  },
  components: {
    ShowFiles,
    FormConfirmPerson
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    moedaFrete: {
      type: String,
      required: true
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        resolve(true)
      })
    },
    hasSlot (name = 'default') {
      return !!this.$slots[ name ] || !!this.$scopedSlots[ name ]
    },
    handleResize () {
      this.screen.width = window.innerWidth
      this.screen.height = window.innerHeight
    }
  },
  computed: {
    modalidade () {
      if (this.isLCL) {
        return 'Marítimo - LCL'
      }
      return 'Marítimo - FCL'
    },
    isLCL () {
      let isLcl = false

      if (this.value && this.value.tipo_carga === 4) {
        isLcl = true
      }

      return isLcl
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    isCupomAdded () {
      let isAdded = false

      if (this.value.cupom && this.value.cupom.id !== null) {
        isAdded = true
      }

      return isAdded
    },
    totalMoeda () {
      let total = []
      let valorSeguro = 0
      if (this.value.adicionais.seguro.freteMercadoria) {
        valorSeguro = this.$util.getNumberFromFormated(this.value.adicionais.seguro.valor_seguro)
      }

      for (let i in this.value.total) {
        let totalMoeda = this.value.total[i]
        let totalValor = totalMoeda.total

        total.push({
          moeda: totalMoeda.moeda,
          total: totalValor
        })
      }

      if (valorSeguro > 0) {
        let index = total.findIndex((e) => {
          return e.moeda === 'USD'
        })

        if (index !== -1) {
          total[index].total = total[index].total + valorSeguro
        } else {
          total.push({
            moeda: 'USD',
            total: valorSeguro
          })
        }
      }

      if (this.value.cupom.valor > 0) {
        let index = total.findIndex((e) => {
          return e.moeda === this.value.cupom.moeda
        })

        if (index !== -1) {
          total[index].total = total[index].total - this.value.cupom.valor
        } else {
          total.push({
            moeda: this.value.cupom.moeda,
            total: this.value.cupom.valor
          })
        }
      }

      return total
    },
    screenWidth () {
      if (this.screen == null) {
        return false
      }
      return this.screen.width > 768
    },
    taxasFrete () {
      let _this = this
      let taxas = _this.value.taxas.filter((e) => {
        return e.tipo === 1
      })

      if (taxas === undefined) {
        return []
      }

      return taxas
    },
    taxasOrigem () {
      let _this = this
      let taxas = _this.value.taxas.filter((e) => {
        return e.tipo === 2
      })

      if (taxas === undefined) {
        taxas = []
      }

      // Se expo adiciona seguro
      if (_this.value.tipo_operacao === 1 && _this.value.adicionais.seguro.freteMercadoria) {
        taxas.push(
          {
            'descricao': 'SEGURO',
            'moeda': _this.moedaFrete,
            'valor_unitario': _this.$util.getNumberFromFormated(_this.value.adicionais.seguro.valor_seguro),
            'valor_pagamento_minimo': 0,
            'quantidade': 1,
            'tipo': 2,
            'unidade': null
          }
        )
      }

      taxas = taxas.sort((a, b) => {
        if ((a.valor_unitario * a.quantidade) < (b.valor_unitario * b.quantidade)) return 1
        if ((a.valor_unitario * a.quantidade) > (b.valor_unitario * b.quantidade)) return -1
        return 0
      })

      return taxas
    },
    taxasDestino () {
      let _this = this
      let taxas = _this.value.taxas.filter((e) => {
        return e.tipo === 3
      })

      if (taxas === undefined) {
        taxas = []
      }

      // Se impo adiciona seguro
      if (_this.value.tipo_operacao === 2 && _this.value.adicionais.seguro.freteMercadoria) {
        taxas.push(
          {
            'descricao': 'SEGURO',
            'moeda': _this.moedaFrete,
            'valor_unitario': _this.$util.getNumberFromFormated(_this.value.adicionais.seguro.valor_seguro),
            'valor_pagamento_minimo': 0,
            'quantidade': 1,
            'tipo': 3,
            'unidade': null
          }
        )
      }

      taxas = taxas.sort((a, b) => {
        if ((a.valor_unitario * a.quantidade) < (b.valor_unitario * b.quantidade)) return 1
        if ((a.valor_unitario * a.quantidade) > (b.valor_unitario * b.quantidade)) return -1
        return 0
      })

      return taxas
    }
  },
  created () {
    this.id = 'form_' + this._uid
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  // mounted () {
  //   if (this.$hj) {
  //     this.$hj('stateChange', '/proposal/' + this.value.id + '/create/confirm')
  //   }
  // },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  }
}
</script>

<style scoped>
.marked {
  padding: 25px;
  background-color: #f7f7f7;
  color: #5a4d34;
}

.label {
  color: #a7acae;
  display: inline-block;
  /* width: 45%; */
  max-width: 235px;
}

.row-content > .col-12 {
  border-bottom: 1px solid #e8e5de;
  line-height: 2.2em;
}

.row-content.table-content .label {
  max-width: 100%;
  width: 100%;
}

@media (max-width: 640px) {
}
</style>
